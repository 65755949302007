.root {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-core-2);
}

.spiciness {
    display: flex;
    justify-content: center;
    align-items: center;
}

.notSpicy {
    color: #e0e0e0;
}
