.tags {
    list-style: none;
    padding: 0;
    margin-top: var(--spacing-core-8);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-core-2);
}

.tag {
    display: inline-block;
}
