.root {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-core-1);
    padding: 6px var(--spacing-core-3) 6px var(--spacing-core-4);
    text-decoration: none;
    transition: all ease-in 0.2s;
    border-radius: 32px;
}

.primary {
    color: #FFF;
    background: #000;
    border: var(--border-width-core-thick) solid #000;

    &:visited {
        color: #FFF;
    }

    &:hover {
        background: #595959;
        border-color: #595959;
    }
}

.secondary {
    color: #000;
    background: transparent;
    border: var(--border-width-core-thick) solid #000;

    &:visited {
        color: #000;
    }

    &:hover {
        color: #FFF;
        background: #000;
        border-color: #000;
    }
}

.dietIcon {
    fill: currentColor;
}
