@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root,
.stars {
    display: flex;
    justify-content: center;
    align-items: center;
}

.root {
    gap: var(--spacing-core-2);
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-core-2);
}

.count {
    &::before {
        content: '(' / '';
    }

    &::after {
        content: ')' / '';
    }

    @include mq.mq($from: large) {
        &::before,
        &::after {
            display: none;
        }
    }
}

.textLabel {
    display: none;

    @include mq.mq($from: large) {
        display: inline-block;
    }
}
