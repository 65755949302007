.root {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-core-2);
}

.icon {
    flex-shrink: 0;
}

.nutritionValues {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}

.nutritionLink {
    text-transform: lowercase;

    &,
    &:visited {
        cursor: pointer;
        color: #000;
        text-decoration: none;
    }
}
