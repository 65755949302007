@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    flex-grow: 1;
}

@include mq.mq($from: large) {
    .root {
        min-width: 220px;
    }
}
